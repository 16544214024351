<template>
    <svg 
    width="15px"
    height="15px" 
    class="profilegendericon"
    viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: sketchtool 63.1 (101010) - https://sketch.com -->
    <title>52EE27A3-1010-4490-9C6A-5EB61A48970A</title>
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="#2A2B75" class="profileGendersvg" stroke-width="1" fill="" fill-rule="evenodd">
        <g id="Spec" transform="translate(-920.000000, -649.000000)" fill="#EFEFF4" fill-rule="nonzero">
            <g id="Name" transform="translate(920.837501, 649.764268)">
                <path d="M6.66666667,1.66666667 C7.58333333,1.66666667 8.33333333,2.41666667 8.33333333,3.33333333 C8.33333333,4.25 7.58333333,5 6.66666667,5 C5.75,5 5,4.25 5,3.33333333 C5,2.41666667 5.75,1.66666667 6.66666667,1.66666667 L6.66666667,1.66666667 Z M6.66666667,9.16666667 C8.91666667,9.16666667 11.5,10.2416667 11.6666667,10.8333333 L11.6666667,11.6666667 L1.66666667,11.6666667 L1.66666667,10.8416667 C1.83333333,10.2416667 4.41666667,9.16666667 6.66666667,9.16666667 L6.66666667,9.16666667 Z M6.66666667,0 C4.825,0 3.33333333,1.49166667 3.33333333,3.33333333 C3.33333333,5.175 4.825,6.66666667 6.66666667,6.66666667 C8.50833333,6.66666667 10,5.175 10,3.33333333 C10,1.49166667 8.50833333,0 6.66666667,0 L6.66666667,0 Z M6.66666667,7.5 C4.44166667,7.5 0,8.61666667 0,10.8333333 L0,13.3333333 L13.3333333,13.3333333 L13.3333333,10.8333333 C13.3333333,8.61666667 8.89166667,7.5 6.66666667,7.5 L6.66666667,7.5 Z" 
                id="Shape" fill="#2A2B75" class="profileGendersvg"></path>
            </g>
        </g>
    </g>
</svg>
</template>
<script>

export default {
    name:"profileGendericon"
}

</script>
<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.profilegendericon{
  margin :5px;
}
.profileGendersvg{
  fill:$clr-profileAgesvg;
  stroke:$clr-profileAgesvg;
}
</style>
